$gray: #4a4a4a;
$gray-light: #d8d8d8;
$silver-light: #f4f5f7;
$blue: #4a90e2;
$blue-light: #42a5d0;
$blue-dark: #006898;
$orange-light: #fbd249;
$orange-dark: #f5a623;
$red: #ff0000;
$green: #8bc34a;
$yellow: #e9e91a;
$purple: #af00ff;
$light-grey-hover: #d3d3d31f;
$dark-blue-p1: #212240;
$font-color: $gray;
$link-color: $blue-dark;
$bg-color: rgba(240, 240, 240, 0.5);
$icon-bg-color: #eeeef0;
$border-color: #979797;
$border-error: #ff0000;

$font-size-default: 100%; // 16px;
$font-size-h1: 1.5rem; // 24px;
$font-size-h2: 1.125rem; // 18px;
$font-size-p: 0.875rem; // 14px;
$font-size-sub: 0.75rem; // 12px;

$line-height-row: 1.5rem; // 24px;
$padding-row: 0.75rem; // 12px;
